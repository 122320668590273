import React from "react"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import SolidHero from "../../components/solid-hero/solid-hero"
import ClassPreview from "../../components/class-preview/class-preview"
import { graphql, useStaticQuery } from "gatsby"
import AnmeldungCtaSection from "../../components/anmeldung-cta-section/anmeldung-cta-section"
import Accordion from "../../components/accordion/accordion"

const AutoPage = () => {

  const { b, be, b196, b197} = useStaticQuery(
    graphql`
      query {
        b: file(relativePath: { eq: "klasse-b-lifestyle.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        be: file(relativePath: { eq: "klasse-be-lifestyle.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        b196: file(relativePath: { eq: "klasse-b196-lifestyle.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        b197: file(relativePath: { eq: "klasse-b197-lifestyle.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  )
  return <Layout>
    <SEO title="Autoführerscheine im Überblick" description={'Ganz legal einsteigen und losfahren? Geht! Wir begleiten dich gerne auf deinem Weg zum Autoführerschein in den Klassen B, BE, B196 und B197.'}/>
    <SolidHero primary title={"Autoführerschein"} subtitle={() => "Alle Auto-Klassen im Überblick"}></SolidHero>
    <ClassPreview linkText={"Mehr erfahren"} linkPath={"/auto/klasse-b/"} title={"Klasse B"}
                  text={"Der Klassiker. Entscheidest du dich für den Autoführerschein der Klasse B, darfst du erstmal natürlich ganz normale PKW‘s fahren. Voraussetzung dabei: Nicht mehr als acht Personen befördern und die maximale Gesamtmasse von 3,5 Tonnen einhalten. Zum anderen berechtigt dich dein Klasse B-Führerschein aber auch zu Fahrzeugen der Klassen L (Land- und forstwirtschaftliche Zugmaschinen mit maximal 40 km/h) und AM (Roller und Mopeds, jeweils mit maximal 45 km/h). Und auch die Anhängerkupplung freut sich: Schon mit Klasse B darfst du einen Anhänger mit einer Gesamtmasse von 750 Kilo ziehen – mehr geht, solange die Kombi von Auto + Anhänger die 3,5 Tonnen nicht übersteigt."}
                  imageFluid={b.childImageSharp.fluid} imageAlt={'Klasse B'}></ClassPreview>
    <ClassPreview linkText={"Mehr erfahren"} linkPath={"/auto/klasse-be/"} title={"Klasse BE"}
                  text={"Steht für BEsonders schwer – meint aber zum Glück nur die erlaubte Zuglast nach bestandener Prüfung. Mit dem Führerschein der Klasse BE darfst du alles, was du mit der Klasse B auch schon darfst – zusätzlich erhöht sich aber deine zulässige Gesamtmasse auf insgesamt 7 Tonnen. Einzige Bedingung dabei: Dein Fahrzeug darf davon maximal 3,5 ausmachen, der Rest gehört dem Anhänger. Übrigens: Fürs „E“ musst du keine zusätzliche Theorieprüfung absolvieren. Es fallen lediglich eine extra Fahrprüfung sowie natürlich ein paar zusätzliche Übungsstunden und gesetzlich vorgeschriebene Sonderfahrten an. Kein Problem, wenn du mitziehst!"}
                  imageFluid={be.childImageSharp.fluid} imageAlt={'Klasse BE'}></ClassPreview>
    <ClassPreview linkText={"Mehr erfahren"} linkPath={"/auto/klasse-b196/"} title={"Klasse B196"}
                  text={"Der Führerschein mit besonderen Vorzügen. Ganz einfach gesagt darfst du mit der 196er-Erweiterung für die Klasse B zusätzlich auf Motorräder aus der Fahrerlaubnisklasse A1 steigen. Um ihn eintragen zu lassen, musst du den Klasse B-Führerschein mindestens fünf Jahre besitzen sowie das Mindestalter von 25 Jahren erreicht haben. Dann freuen sich Fahrzeuge mit maximal 125 cm³ Hubraum und bis zu 11 kW Motorleistung auf dich und deine Fahrkünste – übrigens sogar mit Beiwagen."}
                  imageFluid={b196.childImageSharp.fluid} imageAlt={'Klasse B196'}></ClassPreview>
    <ClassPreview linkText={"Mehr erfahren"} linkPath={"/auto/klasse-b197/"} title={"Klasse B197"}
                  text={"Die Neue unter den B-Erweiterungen. Seit dem 1.4.2021 darfst du deine reguläre Fahrprüfung mit einem Automatikfahrzeug absolvieren und trotzdem Schaltfahrzeuge fahren. Dafür musst du dich nur für die 197er-Erweiterung entscheiden und neben ein paar Schaltstunden einen 15-Minütigen Test mit uns, bzw. deinem/deiner Fahrlehrenden absolvieren – eine extra Schaltprüfung fällt nicht an. Dein Nervenkostüm freut sich über weniger Stress beim Fahren lernen, die Umwelt freut sich über weniger Verschleiß und bessere Abgaswerte. Und du dich über einen vollwertigen Führerschein, der auch im Ausland gültig ist."}
                  imageFluid={b197.childImageSharp.fluid} imageAlt={'Klasse B197'}></ClassPreview>
    <AnmeldungCtaSection claimIndex={2}></AnmeldungCtaSection>

    {/*<Accordion questionsAnswers={[*/}
    {/*  { "question": "test", "answer": "test" },*/}
    {/*  {*/}
    {/*    "question": "test",*/}
    {/*    "answer": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",*/}
    {/*  },*/}
    {/*  {*/}
    {/*    "question": "test",*/}
    {/*    "answer": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",*/}
    {/*  },*/}
    {/*  {*/}
    {/*    "question": "test",*/}
    {/*    "answer": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",*/}
    {/*  }*/}
    {/*]}></Accordion>*/}
  </Layout>
}


export default AutoPage
